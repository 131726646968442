/**
 * Checks titles for diacritics (ex. ô, à, ç, è, é...)
 * -- Runs on H1, H2 and H3 fields in ModuleText, CtaHero, StandardHero
 * -- Doesn't detect Chinese characters, as intended
 * @param  {string} title Title being processed
 * @return {boolean} True if diacritical marks exist
 */

const accents = (title) => {
  return title && title.match ? title.match(/[À-ÖØ-öø-ÿ]/) : false;
};

export default accents;
