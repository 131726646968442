import React from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import replace from 'lodash/replace';
import xss from 'xss';

export const TradeMarkPropTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  bodyContent: PropTypes.bool
};

const TradeMark = ({ children, as = 'span', className, bodyContent }) => {
  const Component = as;
  const tradeMark = React.useMemo(() => {
    if (includes(children, '™') || includes(children, '(TM)')) {
      if (bodyContent) {
        return children.replace(/\([TM^)]*\)/g, '<sup>TM</sup>').replace(/™/g, '<sup>TM</sup>');
      }
      return includes(children, '™')
        ? replace(children, /™/g, '<sup>TM</sup>')
        : replace(children, /\([TM^)]*\)/g, '<sup>TM</sup>');
    }
    return null;
  }, [bodyContent, children]);

  // Check to see if there's HTML tags inside and use a div
  if (children?.includes('</') || tradeMark?.includes('</')) {
    return (
      <span
        dangerouslySetInnerHTML={{ __html: `${xss(tradeMark || children)}` }}
        className={className}
      />
    );
  }
  return (
    <Component
      dangerouslySetInnerHTML={{ __html: `${xss(tradeMark || children)}` }}
      className={className}
    />
  );
};

TradeMark.propTypes = TradeMarkPropTypes;
TradeMark.defaultProps = {
  bodyContent: false,
  as: 'span',
  className: null
};

export default React.memo(TradeMark);
